<template functional>
  <div>
    <v-card outlined class="mb-4" elevation="2">
      <v-card-title>About E.S.A.V. De Beunbazen</v-card-title>
      <v-card-text class="mt-4">
        The Eindhovense Studenten Automobielen Vereniging 'De Beunbazen' (The
        Student Automobiles Association of Eindhoven) is a new association for
        car enthusiasts. It was founded in the summer of 2019 and was able to
        reach about 50 members in just a few months.<br /><br />
        Our association strives towards the enhancement of Motorsports and
        enriching student life. We achieve that by maintaining and enhancing the
        car culture among students, the gathering of knowledge about automobiles
        by working together on projects and by bringing people together with
        love for (their) cars.
      </v-card-text>
    </v-card>
    <v-card outlined class="mb-4" elevation="2">
      <v-img
        height="350"
        src="frontend-images/meets.webp"
        class="white--text align-end"
      >
        <v-card-title>ESAV Car Meets</v-card-title>
      </v-img>
      <v-card-text>
        One of the main activities we do at ESAV is to gather with members at an
        empty parking lot. That might sound boring, but we car enthusiasts enjoy
        it! Not only looking at each other’s cars or going for a little drive
        around the parking lot, also just catching up with other members. We do
        regular meets, two every month and there are special themed meets, like
        a Christmas meet, Carnaval meet and post-exam meets where we organize
        something special, like food and drinks or themed clothing.<br />
        <br />
        If you would like to join one of our meets without obligation, send an
        email to <a href="mailto:bestuur@esav.nl">bestuur@esav.nl</a> and we can
        send you the details!
      </v-card-text>
    </v-card>
    <v-card outlined class="mb-4" elevation="2">
      <v-img
        height="350"
        src="frontend-images/trips.webp"
        class="white--text align-end"
      >
        <v-card-title>ESAV Trips</v-card-title>
      </v-img>
      <v-card-text>
        Not only do we like to work on our cars, we also like to drive them! We
        organize trips for a single day, a weekend or a week. Usually the week
        trip is for the summer holiday, where we go further away, like the Alps.
        Weekend trips are more regular, about one every season (sometimes even
        in winter!). They are mostly headed towards the Ardennes and Eiffel
        regions. Places near home, but with very good driving roads. The single
        day trips are usually near Eindhoven, where we just go for a drive
        together, trying our best to follow a specific route!
      </v-card-text>
    </v-card>
    <v-card outlined class="mb-4" elevation="2">
      <v-card-title>Sponsors and deals</v-card-title>
      <v-card-text class="mt-4">
        If you still think the membership fee is not worth it, we have good
        deals with local auto part shops who not only sponsor our association,
        but they also give members discounts. These shops are also very close to
        our workshop and they have a lot. So if you find out you are missing a
        part, you can go there and most likely they will have what you are
        looking for. And because of the discounts, you can save a lot of money.
        Doing an oil change can already pay your membership fee back!
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
export default {
  name: "AboutPage",
};
</script>

<style scoped></style>
