<template>
  <v-footer dark padless>
    <v-card
        flat
        tile
        class="blue-grey lighten-1 white--text text-center"
        width="100%"
    >
      <v-card-text>
        <v-btn
            v-for="(url, icon) in icons"
            :key="icon"
            class="mx-4 white--text"
            icon
            :href="url"
            target="_blank"
        >
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
      </v-card-text>

      <v-card-text class="white--text pt-0">
        Eindhovense Studenten Automobiel Vereniging De Beunbazen
      </v-card-text>

      <v-divider></v-divider>

      <v-card-text class="white--text">
        {{ new Date().getFullYear() }} — Made by
        <strong
        ><a
            href="https://www.linkedin.com/in/bart-pijnenburg/"
            class="white--text"
            target="_blank"
        >
          Bart Pijnenburg</a
        ></strong
        >
      </v-card-text>
<!--      <v-divider></v-divider>-->
      <v-card-text class="white--text">
        <a href="/Privacy%20Statement.pdf" target="_blank" class="white--text"
        >Privacy Statement</a>
      </v-card-text>
    </v-card>
  </v-footer>
</template>

<script>
export default {
  name: "FooterComponent",
  data: () => ({
    icons: {
      "mdi-facebook": "https://www.facebook.com/esavdebeunbazen",
      "mdi-linkedin":
          "https://www.linkedin.com/company/esav-de-beunbazen/about/",
      "mdi-instagram": "https://www.instagram.com/esavdebeunbazen/",
    },
  }),
};
</script>

<style scoped></style>
