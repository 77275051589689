<template>
  <v-expansion-panel :loading="loading">
    <v-expansion-panel-header>
      <h2 v-if="!isMobile">{{ title }}</h2>
      <h3 v-if="isMobile">{{ title }}</h3>
      <v-spacer/>
      <span v-if="user_is_participant" class="text-right">(subscribed)</span>
    </v-expansion-panel-header>
    <v-expansion-panel-content>
      <p><strong>Start: </strong>{{ activity_start | parse_date }}</p>
      <p><strong>End: </strong>{{ activity_end | parse_date }}</p>
      <p><strong>Location: </strong>{{ location }}</p>
      <p>
        <strong>Capacity: </strong>{{ amount_of_participants }} /
        {{ capacity > 0 ? capacity : "∞" }}
      </p>
      <p><strong>Costs: </strong>{{ costs }}</p>

      <div v-html="description"></div>
      <v-row>
      <v-btn
          v-if="can_register && !user_is_participant"
          class="ma-2"
          elevation="2"
          color="primary"
          :disabled="loading"
          v-on:click="registerParticipant"
      >
        Sign up
        <template v-slot:loader>
          <span>Loading...</span>
        </template>
      </v-btn>
      <v-btn
          v-if="can_deregister"
          class="ma-2"
          elevation="2"
          color="primary"
          :disabled="loading"
          v-on:click="deRegister"
      >
        De-register
        <template v-slot:loader>
          <span>Loading...</span>
        </template>
      </v-btn>
      <v-btn
        v-if="can_pay"
        class="ma-2"
        elevation="2"
        color="primary"
        :disabled="loading || payment_started"
        v-on:click="getPaymentLink"
        >
        Start payment
        <template v-slot:loader>
          <span>Loading...</span>
        </template>
      </v-btn>
      <v-btn
          v-if="!can_deregister && !can_register && user_is_participant"
          class="ma-2"
          color="primary"
          elevation="2"
          :disabled="loading"
          v-on:click="sendIt"
      >
        Closed
        <template v-slot:loader>
          <span>Loading...</span>
        </template>
      </v-btn>
      <ParticipantsModal
          class="mt-4 mb-4"
          v-bind:activity_id="activity_id"
          v-bind:amount_of_participants="amount_of_participants"
          v-bind:capacity="capacity > 0 ? capacity : '∞'"
      ></ParticipantsModal>
      <p v-if="user_is_participant">You are subscribed to this activity.</p>
      </v-row>
    </v-expansion-panel-content>
  </v-expansion-panel>
</template>

<script>
import {
  createActivityEntry,
  deleteActivityEntry,
  getPaymentLink,
} from "@/services/ActivityService";
import ParticipantsModal from "./ParticipantsModal";
import vue from "vue";

export default {
  name: "ActivityItem",
  components: {ParticipantsModal},
  data() {
    return {
      loading: false,
      isMobile: false,
      payment_loading: false,
      payment_link: null,
      payment_started: false,
    };
  },
  props: [
    "title",
    "description",
    "activity_id",
    "can_register",
    "can_deregister",
    "can_pay",
    "user_is_participant",
    "location",
    "capacity",
    "costs",
    "amount_of_participants",
    "activity_end",
    "activity_start",
    "isLinked",
  ],
  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, {passive: true});
  },
  mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, {passive: true});
  },
  filters: {
    parse_date: function (value) {
      if (!value) return "";
      let date = new Date(value);
      console.log(date);
      return date.toLocaleString("en-GB", {timeZone: "UTC"});
    },
  },
  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 960;
    },
    async registerParticipant() {
      // TODO move to state
      this.loading = true;
      await createActivityEntry(this.activity_id)
          .then(() => {
            this.can_register = false;
            this.can_deregister = true;
            if (this.costs > 0.0){
              this.can_pay = true;
            }
            this.amount_of_participants += 1;
            this.loading = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },
    async getPaymentLink() {
      this.payment_loading = true;
      await getPaymentLink(this.activity_id)
          .then((response) => {
            this.payment_link = response.payment_url;
            this.payment_loading = false;
            window.open(this.payment_link, "_blank");
            this.payment_started = true;
          })
          .catch(() => {
            vue.notify({
              type: "error",
              title: "Could not retrieve payment link.",
            });
          });
    },
    async deRegister() {
      this.loading = true;
      deleteActivityEntry(this.activity_id)
          .then(() => {
            this.can_register = true;
            this.can_deregister = false;
            this.amount_of_participants -= 1;
            this.user_is_participant = false;
            this.loading = false;
            this.can_pay = false;
          })
          .catch((error) => {
            console.log(error);
            this.loading = false;
          });
    },
  },
};
</script>

<style scoped></style>
