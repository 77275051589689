<template>
  <v-card class="mx-auto mb-10">
    <v-img class="white--text align-end" height="300px" :src="photo">
      <v-card-title style="opacity: 0.8;">{{ title }}</v-card-title>
    </v-img>

    <v-card-subtitle class="pb-0">
      Written by: {{ author }} on {{ $attrs.date }}
    </v-card-subtitle>

    <v-card-text class="text--primary">
      <div v-if="readMore" :inner-html.prop="content | truncate(350)" />
      <div v-if="!readMore" :inner-html.prop="content" />
    </v-card-text>

    <v-card-actions>
      <v-btn color="orange" text v-if="readMore" v-on:click="extendArticle">
        Read More
      </v-btn>
      <v-btn color="orange" text v-if="!readMore" v-on:click="truncateArticle">
        Read Less
      </v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  name: "NewsItem",
  props: ["title", "content", "photo", "pub_date", "author"],
  data: () => ({
    readMore: true,
  }),
  methods: {
    extendArticle() {
      this.readMore = false;
    },
    truncateArticle() {
      this.readMore = true;
    },
  },
  filters: {
    truncate(value, length) {
      if (!value) return "";
      value = value.toString();
      if (value.length > length) {
        return value.substring(0, length) + "...";
      } else {
        return value;
      }
    },
  },
};
</script>
