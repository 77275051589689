import { render, staticRenderFns } from "./ContactPage.vue?vue&type=template&id=d7fd1a80&scoped=true&functional=true"
import script from "./ContactPage.vue?vue&type=script&lang=js"
export * from "./ContactPage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  true,
  null,
  "d7fd1a80",
  null
  
)

export default component.exports