<template>
  <v-card outlined :loading="loading">
    <v-card-title>Log-in</v-card-title>
    <v-divider></v-divider>
    <v-alert v-if="incorrectAuth" type="error" dense class="ma-4">
      Username or password is incorrect.
    </v-alert>
    <v-form ref="form" @submit.prevent="login">
      <v-card-text>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field v-model="username" label="Username" required />
            <div v-show="tried && !username" class="invalid-feedback">
              Username is required
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" sm="12">
            <v-text-field type="password" v-model="password" label="Password" />
            <div v-show="tried && !password" class="invalid-feedback">
              Password is required
            </div>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          type="submit"
          color="primary"
          :disabled="loading || !password || !username"
        >
          Log-in
        </v-btn>
      </v-card-actions>
    </v-form>
  </v-card>
</template>

<script>
import { validationMixin } from "vuelidate";

export default {
  name: "LoginForm",
  mixins: [validationMixin],

  validations: {},

  data: () => ({
    username: "",
    password: "",
    loading: false,
    incorrectAuth: false,
    tried: false,
  }),
  computed: {
    // if user is already logged in
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },

  methods: {
    async login() {
      this.loading = this.tried = true;
      await this.$store
        .dispatch("auth/userLogin", {
          username: this.username,
          password: this.password,
        })
        .then(() => {
          if (this.$route.query.redirect) {
            this.$router.push(this.$route.query.redirect || "/");
          } else {
            this.$router.push({ name: "home" });
          }
          this.$notify({
            type: "success",
            title: "Log-in successful",
            message: "You have been logged in.",
          });
        })
        .catch(() => {
          this.incorrectAuth = true;
        });
      this.loading = false;
    },
  },
};
</script>

<style scoped></style>
