<template>
  <v-card class="mx-auto" tile>
    <v-list>
      <v-card-title>Links</v-card-title>
      <v-divider></v-divider>
      <v-row>
        <v-col>
          <v-list-item-group color="primary">
            <v-list-item
              v-for="(item, i) in items"
              :key="i"
              link
              :href="item.url"
              target="_blank"
            >
              <v-list-item-icon>
                <v-icon v-text="item.icon"></v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title v-text="item.text"></v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list-item-group>
        </v-col>
      </v-row>
    </v-list>
  </v-card>
</template>

<script>
export default {
  name: "LinkBoxComponent",
  data: () => ({
    items: [
      {
        text: "Sponsorkliks",
        icon: "mdi-cash",
        url: "https://www.sponsorkliks.com/products/shops.php?club=11834&cn=nl&ln=nl",
      },
    ],
  }),
};
</script>

<style scoped></style>
