<template>
  <v-app id="inspire">
    <v-card class="overflow-hidden" height="100%">
      <v-app-bar color="blue-grey lighten-1" dark dense fixed>
        <v-app-bar-nav-icon
          @click="drawer = true"
          v-if="isMobile"
        ></v-app-bar-nav-icon>

        <v-toolbar-title>E.S.A.V. De Beunbazen</v-toolbar-title>
        <v-spacer v-if="!isMobile"></v-spacer>

        <v-toolbar-items class="hidden-sm-and-down" v-if="!isMobile" >
          <v-btn text link :to="'/'">Home</v-btn>
          <v-btn text link :to="'/about-us'">About Us</v-btn>
          <v-menu
            open-on-hover
            offset-y
            transition="slide-x-transition"
            bottom
            right
            text
            link
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on"> E.S.A.V. </v-btn>
            </template>
            <v-list>
              <v-list-item router text :to="'/boards'">
                <v-list-item-action>
                  <v-list-item-title>Boards</v-list-item-title>
                </v-list-item-action>
              </v-list-item>
              <v-list-item router text :to="'/committees'">
                <v-list-item-action>
                  <v-list-item-title>Committees</v-list-item-title>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>
          <v-btn text link :to="'/join-us'" v-if="!loggedIn">Join ESAV</v-btn>
          <v-btn text link :to="'/contact'">Contact</v-btn>
          <v-btn text link :to="'/login'" v-if="!loggedIn">Login</v-btn>
          <v-menu
            open-on-hover
            offset-y
            transition="slide-x-transition"
            bottom
            right
            text
            link
            v-if="loggedIn"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-btn text v-bind="attrs" v-on="on">Members</v-btn>
            </template>
            <v-list>
              <v-list-item router text :to="'/activities'">
                <v-list-item-action>
                  <v-list-item-title>Activities</v-list-item-title>
                </v-list-item-action>
              </v-list-item>
              <v-list-item router text :to="'/members'">
                <v-list-item-action>
                  <v-list-item-title>Members List</v-list-item-title>
                </v-list-item-action>
              </v-list-item>
              <v-list-item router text :to="'/members/' + this.current_user_id">
                <v-list-item-action>
                  <v-list-item-title>My profile</v-list-item-title>
                </v-list-item-action>
              </v-list-item>
              <v-list-item router text :to="'/downloads'">
                <v-list-item-action>
                  <v-list-item-title>Downloads</v-list-item-title>
                </v-list-item-action>
              </v-list-item>
              <v-list-item router text :to="'/garage'">
                <v-list-item-action>
                  <v-list-item-title>Garage</v-list-item-title>
                </v-list-item-action>
              </v-list-item>

              <v-list-item router text :to="'/change-password'">
                <v-list-item-action>
                  <v-list-item-title>Change Password</v-list-item-title>
                </v-list-item-action>
              </v-list-item>
              <v-list-item
                text
                href="https://beun-shop.creator-spring.com/"
                target="_blank"
              >
                <v-list-item-action>
                  <v-list-item-title>Shop</v-list-item-title>
                </v-list-item-action>
              </v-list-item>
              <v-list-item route text @click="logOut">
                <v-list-item-action>
                  <v-list-item-title>Log Out</v-list-item-title>
                </v-list-item-action>
              </v-list-item>
            </v-list>
          </v-menu>
        </v-toolbar-items>
      </v-app-bar>

      <v-navigation-drawer v-model="drawer" temporary fixed>
        <v-list nav dense>
          <v-list-item-group
            v-model="group"
            active-class="blue-grey--text text--accent-4"
          >
            <v-list-item link :to="'/'">
              <v-list-item-icon>
                <v-icon>mdi-home</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Home</v-list-item-title>
            </v-list-item>
            <v-list-item link :to="'/about-us'">
              <v-list-item-icon>
                <v-icon>mdi-information</v-icon>
              </v-list-item-icon>
              <v-list-item-title>About Us</v-list-item-title>
            </v-list-item>

            <v-list-group :value="false" prepend-icon="mdi-car">
              <template v-slot:activator>
                <v-list-item-title>E.S.A.V.</v-list-item-title>
              </template>
              <v-list-item link :to="'/boards'">
                <v-list-item-icon>
                  <v-icon>mdi-car-wrench</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Boards</v-list-item-title>
              </v-list-item>
              <v-list-item link :to="'/committees'">
                <v-list-item-icon>
                  <v-icon>mdi-car-key</v-icon>
                </v-list-item-icon>
                <v-list-item-title>Committees</v-list-item-title>
              </v-list-item>
            </v-list-group>
            <v-list-item link :to="'/lustrum'">
              <v-list-item-icon>
                <v-icon>mdi-party-popper</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Lustrum</v-list-item-title>
            </v-list-item>
            <v-list-item link :to="'/join-us'" v-if="!loggedIn">
              <v-list-item-icon>
                <v-icon>mdi-account-plus</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Join Us</v-list-item-title>
            </v-list-item>
            <v-list-item link :to="'/contact'">
              <v-list-item-icon>
                <v-icon>mdi-email</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Contact</v-list-item-title>
            </v-list-item>
            <v-list-item link :to="'/login'" v-if="!loggedIn">
              <v-list-item-icon>
                <v-icon>mdi-login</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Members log-in</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
          <v-divider />
          <v-list-item-group
            v-if="loggedIn"
            active-class="blue-grey--text text--accent-4"
          >
            <v-list-item link :to="'/activities'">
              <v-list-item-icon>
                <v-icon>mdi-calendar</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Activities</v-list-item-title>
            </v-list-item>
            <v-list-item link :to="'/members'">
              <v-list-item-icon>
                <v-icon>mdi-list-box</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Members List</v-list-item-title>
            </v-list-item>
            <v-list-item link :to="'/members/' + this.current_user_id">
              <v-list-item-icon>
                <v-icon>mdi-account</v-icon>
              </v-list-item-icon>
              <v-list-item-title>My profile</v-list-item-title>
            </v-list-item>
            <v-list-item link :to="'/downloads'">
              <v-list-item-icon>
                <v-icon>mdi-cloud-download</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Downloads</v-list-item-title>
            </v-list-item>

            <v-list-item link :to="'/garage'">
              <v-list-item-icon>
                <v-icon>mdi-car-multiple</v-icon>
              </v-list-item-icon>
              <v-list-item-title>ESAV Garage</v-list-item-title>
            </v-list-item>
            <v-list-item link :to="'/change-password'">
              <v-list-item-icon>
                <v-icon>mdi-account-edit</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Change Password</v-list-item-title>
            </v-list-item>
            <v-list-item
              link
              href="https://beun-shop.creator-spring.com/"
              target="_blank"
            >
              <v-list-item-icon>
                <v-icon>mdi-cart</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Shop</v-list-item-title>
            </v-list-item>
            <v-list-item link @click="logOut">
              <v-list-item-icon>
                <v-icon>mdi-logout</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Log Out</v-list-item-title>
            </v-list-item>
          </v-list-item-group>
        </v-list>
      </v-navigation-drawer>
      <v-main class="mt-12">
        <Hero v-if="!(!isHomePage && isMobile)" :isMobile="isMobile" />
        <v-row no-gutters>
          <v-col v-if="!isMobile" xl="1" lg="1" md="0" sm="0" xs="0"></v-col>
          <v-col xl="7" lg="7" md="9" sm="12" xs="12" class="pa-6">
            <router-view />
          </v-col>
          <v-col md="3" sm="12" xs="12" class="pa-6">
            <SidebarComponent />
          </v-col>
          <v-col v-if="!isMobile" xl="1" lg="1" md="0" sm="0" xs="0"></v-col>
        </v-row>
        <FooterComponent />
      </v-main>
    </v-card>
    <notifications position="bottom right" />
  </v-app>
</template>

<script>
import FooterComponent from "@/components/FooterComponent.vue";
import SidebarComponent from "@/components/SidebarComponent.vue";
import Hero from "@/components/Hero.vue";
import { mapGetters } from "vuex";

export default {
  name: "App",

  components: {
    SidebarComponent,
    FooterComponent,
    Hero,
  },

  data: () => ({
    drawer: false,
    isMobile: false,
    group: null,
  }),

  beforeDestroy() {
    if (typeof window === "undefined") return;

    window.removeEventListener("resize", this.onResize, { passive: true });
  },

  methods: {
    onResize() {
      this.isMobile = window.innerWidth < 960;
    },
    logOut() {
      this.$store.dispatch("auth/logOut", { routerPush: { name: "home" } });
    },
  },

  async mounted() {
    this.onResize();
    window.addEventListener("resize", this.onResize, { passive: true });
  },

  computed: {
    // if user is already logged in
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
    isHomePage() {
      return this.$route.path === "/" || this.$route.path === "/home";
    },
    ...mapGetters({ current_user_id: "auth/getCurrentUserId" }),
  },
};
</script>
<style>
p {
  font-size: 0.925rem;
}

.v-card__text {
  font-size: 0.925rem;
}

.vue-notification {
  padding: 16px;
  margin: 0 15px 15px;

  font-size: 14px;

  color: #ffffff;
  background: #44a4fc;
  border-left: 5px solid #187fe7;
}
</style>
