<template>
  <v-container fluid fill-height class="home-hero" >
    <!-- <v-layout justify-center align-center column pa-4>
      <div
        class="display-3 font-weight-black white--text text-xs-center"
        v-if="!isMobile"
      >
        ESAV
      </div>
      <div class="display-3 font-weight-black white--text text-xs-center mb-3">
        De Beunbazen
      </div>
      <div class="display-1 font-weight-bold white--text text-xs-center">
        Eindhoven Student Association for car enthusiasts.
      </div>
    </v-layout> -->
  </v-container>
</template>

<script>
export default {
  name: "HomeHero",
  props: ["isMobile"],
};
</script>

<style scoped>
.home-hero {
  background-size: cover !important;
  background: url("/public/frontend-images/header.webp") no-repeat center center;
  width: 100%;
  height: 450px;
}
</style>
