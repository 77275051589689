<template>
  <v-card>
    <v-card-title>E.S.A.V. Boards</v-card-title>
    <v-divider />
    <v-expansion-panels class="mb-6" v-model="panel">
      <v-expansion-panel v-model="panel">
        <v-expansion-panel-header>
          <h3>Sixth board: Bolt (2024-2025)</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col
              v-for="item in this.board_members.bolt"
              :key="item.profile_id"
              cols="12"
              md="4"
              sm="6"
              xs="12"
              class="pa-4"
            >
              <v-card class="mx-auto" max-width="344">
                <v-img :src="item.photo" height="300px"></v-img>

                <v-card-title>
                  {{ item.name }}
                </v-card-title>

                <v-card-subtitle class="mt-4">
                  {{ item.board_function }}
                </v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    color="orange lighten-2"
                    text
                    v-if="loggedIn"
                    :to="'/members/' + item.profile_id"
                  >
                    Open profile
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-model="panel">
        <v-expansion-panel-header>
          <h3>Fifth board: Overdrive (2023-2024)</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col
              v-for="item in this.board_members.overdrive"
              :key="item.profile_id"
              cols="12"
              md="4"
              sm="6"
              xs="12"
              class="pa-4"
            >
              <v-card class="mx-auto" max-width="344">
                <v-img :src="item.photo" height="300px"></v-img>

                <v-card-title>
                  {{ item.name }}
                </v-card-title>

                <v-card-subtitle class="mt-4">
                  {{ item.board_function }}
                </v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    color="orange lighten-2"
                    text
                    v-if="loggedIn"
                    :to="'/members/' + item.profile_id"
                  >
                    Open profile
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-model="panel">
        <v-expansion-panel-header>
          <h3>Fourth board: Quattro (2022-2023)</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col
              v-for="item in this.board_members.quattro"
              :key="item.profile_id"
              cols="12"
              md="4"
              sm="6"
              xs="12"
              class="pa-4"
            >
              <v-card class="mx-auto" max-width="344">
                <v-img :src="item.photo" height="300px"></v-img>

                <v-card-title>
                  {{ item.name }}
                </v-card-title>

                <v-card-subtitle class="mt-4">
                  {{ item.board_function }}
                </v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    color="orange lighten-2"
                    text
                    v-if="loggedIn"
                    :to="'/members/' + item.profile_id"
                  >
                    Open profile
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-model="panel">
        <v-expansion-panel-header>
          <h3>Third board: GT3 (2021-2022)</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col
              v-for="item in this.board_members.gt3"
              :key="item.profile_id"
              cols="12"
              md="4"
              sm="6"
              xs="12"
              class="pa-4"
            >
              <v-card class="mx-auto" max-width="344">
                <v-img
                  :src="item.photo"
                  height="300px"
                  position="top center"
                ></v-img>

                <v-card-title>
                  {{ item.name }}
                </v-card-title>

                <v-card-subtitle class="mt-4">
                  {{ item.board_function }}
                </v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    color="orange lighten-2"
                    text
                    v-if="loggedIn"
                    :to="'/members/' + item.profile_id"
                  >
                    Open profile
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-model="panel">
        <v-expansion-panel-header>
          <h3>Second board: MK2 (2020-2021)</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col
              v-for="item in this.board_members.MK2"
              :key="item.profile_id"
              cols="12"
              md="4"
              sm="6"
              xs="12"
              class="pa-4"
            >
              <v-card class="mx-auto" max-width="344">
                <v-img :src="item.photo" height="300px"></v-img>

                <v-card-title>
                  {{ item.name }}
                </v-card-title>

                <v-card-subtitle class="mt-4">
                  {{ item.board_function }}
                </v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    color="orange lighten-2"
                    text
                    v-if="loggedIn"
                    :to="'/members/' + item.profile_id"
                  >
                    Open profile
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
      <v-expansion-panel v-model="panel">
        <v-expansion-panel-header>
          <h3>Founding board: First Gear (2019-2020)</h3>
        </v-expansion-panel-header>
        <v-expansion-panel-content>
          <v-row no-gutters>
            <v-col
              v-for="item in this.board_members.first_gear"
              :key="item.profile_id"
              cols="12"
              md="4"
              sm="6"
              xs="12"
              class="pa-4"
            >
              <v-card class="mx-auto" max-width="344">
                <v-img
                  :src="item.photo"
                  height="300px"
                  position="top center"
                ></v-img>

                <v-card-title>
                  {{ item.name }}
                </v-card-title>

                <v-card-subtitle class="mt-4">
                  {{ item.board_function }}
                </v-card-subtitle>

                <v-card-actions>
                  <v-btn
                    color="orange lighten-2"
                    text
                    v-if="loggedIn"
                    :to="'/members/' + item.profile_id"
                  >
                    Open profile
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-col>
          </v-row>
        </v-expansion-panel-content>
      </v-expansion-panel>
    </v-expansion-panels>
  </v-card>
</template>

<script>
export default {
  name: "BoardPage",
  data() {
    return {
      panel: 0,
      board_members: {

        bolt: [
          {
            board_function: "Chair",
            name: "Rinus van den Broek",
            profile_id: "",
            photo: "/bestuur-fotos/bolt/RinusBolt.jpg",
          },
          {
            board_function: "Secretary",
            name: "Job van Houten",
            profile_id: "",
            photo: "/bestuur-fotos/bolt/JobBolt.jpg",
          },
          {
            board_function: "Treasurer",
            name: "Lars van Hattum",
            profile_id: "",
            photo: "/bestuur-fotos/bolt/LarsBolt.jpg",
          },
          {
            board_function: "External Affairs",
            name: "Rob Berendse",
            profile_id: "",
            photo: "/bestuur-fotos/bolt/RobBolt.jpg",
          },
        ],
        overdrive: [
          {
            board_function: "Chair",
            name: "Maud Slütter",
            profile_id: "216",
            photo: "/bestuur-fotos/overdrive/maud.webp",
          },
          {
            board_function: "Secretary",
            name: "Fenna Sigmond",
            profile_id: "170",
            photo: "/bestuur-fotos/overdrive/fenna.webp",
          },
          {
            board_function: "Treasurer",
            name: "Joris Scheublin",
            profile_id: "186",
            photo: "/bestuur-fotos/overdrive/joris.webp",
          },
          {
            board_function: "Internal Affairs",
            name: "Samuel Leenknegt",
            profile_id: "247",
            photo: "/bestuur-fotos/overdrive/samuel.webp",
          },
        ],
        quattro: [
          {
            board_function: "Chairman",
            name: "Robin Boots",
            profile_id: "229",
            photo: "/bestuur-fotos/ESAV_Quattro_Bestuursfoto-1110102.webp",
          },
          {
            board_function: "Secretary",
            name: "Kevin van den Boom",
            profile_id: "2",
            photo: "/bestuur-fotos/ESAV_Quattro_Bestuursfoto-1110056.webp",
          },
          {
            board_function: "Treasurer",
            name: "Remco List",
            profile_id: "233",
            photo: "/bestuur-fotos/ESAV_Quattro_Bestuursfoto-1110071.webp",
          },
          {
            board_function: "Commissioner of external affairs",
            name: "Simon Bergman",
            profile_id: "239",
            photo: "/bestuur-fotos/ESAV_Quattro_Bestuursfoto-1110066.webp",
          },
        ],
        gt3: [
          {
            board_function: "Chair",
            name: "Mark Hofman",
            profile_id: "208",
            photo: "/bestuur-fotos/mHofmanGT3.jpg",
          },
          {
            board_function: "Secretary",
            name: "Sebastiaan Wories",
            profile_id: "148",
            photo: "/bestuur-fotos/sWoriesGT3.jpg",
          },
          {
            board_function: "Treasurer",
            name: "Walter Nijhout",
            profile_id: "266",
            photo: "/bestuur-fotos/wNijhoutGT3.jpg",
          },
          {
            board_function: "Commissioner of external affairs",
            name: "Koen Mies",
            profile_id: "194",
            photo: "/bestuur-fotos/kmiesGT3.jpg",
          },
        ],
        MK2: [
          {
            board_function: "Chair",
            name: "Boaz Broeren",
            profile_id: "",
            photo: "",
          },
          {
            board_function: "Secretary",
            name: "Wimco Veerman",
            profile_id: "",
            photo: "",
          },
          {
            board_function: "Treasurer",
            name: "Rob Schaafsma",
            profile_id: "",
            photo: "",
          },
        ],
        first_gear: [
        {
            board_function: "Chair",
            name: "Harald List",
            profile_id: "176",
            photo: "",
          },
          {
            board_function: "Secretary",
            name: "Arthur Viitanen",
            profile_id: "146",
            photo: "",
          },
          {
            board_function: "Treasurer",
            name: "Bart Pijnenburg",
            profile_id: "1",
            photo: "",
          },
        ]
      },
    };
  },
  computed: {
    // if user is already logged in
    loggedIn() {
      return this.$store.state.auth.status.loggedIn;
    },
  },
};
</script>

<style scoped></style>
